import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { setStorageData, toastMessage } from "utils/helper";
import { login } from "apiServices/app";

interface WithFormComponentState {
  password: string;
  email: string;
}

const LoginFormComponent = () => {
  const [formData, setFormData] = useState<WithFormComponentState>({
    password: "",
    email: "",
  });
  const [onBtnLoader, setShowBtnLoader] = useState(false);
  const history = useHistory();

  const handleLogin = async () => {
    if (formData.email !== "" && formData.password !== "") {
      setShowBtnLoader(true);
      login(formData)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.success === false) {
            setShowBtnLoader(false);
            toastMessage(responseData.message);
          } else {
            toastMessage("Signin successfully");
            setStorageData("ppay:email", formData.email);
            setStorageData("ppay:user", responseData.loginToken);
            setShowBtnLoader(false);
            history.push("/pos");
          }
        })
        .catch((error) => {
          setShowBtnLoader(false);
          console.log(error);
        });
    } else {
      // toastMessage("All fields are required");
    }
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        name="email"
        label="Username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input
          placeholder="Enter username"
          autoComplete="new-email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        name="Password"
        label="Password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder="Enter password"
          autoComplete="new-password"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => !onBtnLoader && handleLogin()}
          className="commonButton"
        >
          {onBtnLoader ? <LoadingOutlined className="spinLader" /> : "Submit"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginFormComponent;
