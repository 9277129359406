import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { Email, isValidUsername, toastMessage } from "utils/helper";
import WithQRComponent from "../WithQRComponent";

interface SignupcomponentState {
  username: string;
  password: string;
  email: string;
}

const Signupcomponent = ({ setIsSignWithQr, setSignFormData }: any) => {
  const [formData, setFormData] = useState<SignupcomponentState>({
    username: "",
    password: "",
    email: "",
  });

  const formValidation = () => {
    if (
      formData.email !== "" &&
      formData.username !== "" &&
      formData.password !== ""
    ) {
      const validUName = isValidUsername(formData.username);
      const validEmail = Email(formData.email);
      if (!validEmail && validUName) {
        setIsSignWithQr(true);
        setSignFormData(formData);
      } else {
        toastMessage("Please fillup proper email");
        return;
      }
    } else {
      // toastMessage("Something went wrong");
    }
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      layout="vertical"
      autoComplete="off"
    >
      <Form.Item
        name="username"
        label="Username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input
          placeholder="Enter username"
          value={formData.username}
          onChange={(e) =>
            setFormData({ ...formData, username: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input
          placeholder="Enter email"
          autoComplete="new-email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder="Enter password"
          autoComplete="new-password"
          value={formData.password}
          onChange={(e) =>
            setFormData({ ...formData, password: e.target.value })
          }
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          onClick={formValidation}
          className="commonButton"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Signupcomponent;
