import React, { useEffect, useState } from "react";
import PosTable from "../PosTable";
import { useHistory } from "react-router-dom";
import { getPaylinkAPI } from "apiServices/paylinks";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const POSView = () => {
  const [orderList, setOrderList] = useState<any[]>([]);
  const [ordersText, setOrdersText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();

  const fetchOrders = (walletEmail: any) => {
    setShowLoader(true);
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            setShowLoader(false);
            setOrdersText("OOPS you dont have any record");
          } else {
            const paylinksData = responseData.filter(
              (item: any) =>
                item.order_type === "POS" || item.order_type === "Ecommerce"
            );
            if (paylinksData.length > 0) {
              setOrdersText("");
              setShowLoader(false);
              setOrderList(paylinksData.reverse());
            } else {
              setShowLoader(false);
              setOrdersText("OOPS you dont have any record");
            }
          }
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    const walletEmail = localStorage.getItem("ppay:email");
    if (walletEmail == null || walletEmail == undefined) {
      return history.push("/login");
    } else {
      fetchOrders(walletEmail);
    }
  }, []);

  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <PosTable orders={orderList} ordersText={ordersText} />
      )}
    </>
  );
};

export default POSView;
