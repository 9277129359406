/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CalenderIcon, CheckIcon, PendingIcon } from "svgIcon";
import { selectedChainIcon, toUppercaseLetter } from "utils/helper";
import {
  getMerchantOrderDetails,
  getMerchantOrderProduct,
} from "apiServices/orders";
import POSViewModal from "components/Modal/POSViewModal";
import ModalComponent from "components/FormComponent/ModalComponent";

interface DataType {
  key: React.Key;
  payment_id: string;
  amount: string;
  status: string;
  coin: string;
  order_type: string;
  date_time: string;
}

// interface DataType {
//   key: React.Key;
// }

const POSTable = (props: any) => {
  const { orders, ordersText } = props;
  const [orderContent, setOrderContent] = useState<any>({});
  const [orderDetailsContent, setOrderDetailsContent] = useState<any>({});
  const [productContent, setProductContent] = useState<any>([]);
  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const onViewClick = (getOrder: any) => {
    try {
      getMerchantOrderProduct(getOrder.order_id).then((response) => {
        const responseData = response.data || response;
        setProductContent(responseData);
        setOrderContent(getOrder);
        setShowGenerateModal(true);
      });
    } catch (error: any) {}
    if (
      getOrder.status === "completed" &&
      getOrder.order_type === "Ecommerce"
    ) {
      try {
        getMerchantOrderDetails(getOrder.order_id).then((response) => {
          const responseData = response.data || response;
          if (responseData && responseData[0]) {
            setOrderDetailsContent(responseData[0]);
          }
        });
      } catch (error: any) {}
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date Time",
      dataIndex: "date_time",
      key: "date_time",
    },
    {
      title: "Type",
      dataIndex: "order_type",
      key: "order_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      dataIndex: "operation",
      width: 100,
    },
  ];

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          sr_no: index + 1,
          amount: (
            <div className="tAmount">
              {item.amount === null ? "-" : item.amount}
            </div>
          ),
          date_time: (
            <div className="tDatetime">
              {item.status === "completed" ? (
                <>
                  <span>
                    <CalenderIcon />
                  </span>
                  {item.date_time}{" "}
                </>
              ) : (
                "-"
              )}
            </div>
          ),
          order_type: <div className="tType">{item.order_type}</div>,
          status:
            item.status === "completed" ? (
              <div className="tStatus tStatusCompleted">
                <span>
                  <CheckIcon />
                </span>
                {item.status && toUppercaseLetter(item.status)}
              </div>
            ) : (
              <div className="tStatus tStatusPending">
                <span>
                  <PendingIcon />
                </span>
                {item.status && toUppercaseLetter(item.status)}
              </div>
            ),
          operation: <Button onClick={() => onViewClick(item)}>View</Button>,
        };
      })
    );
  };

  return (
    <>
      <Table dataSource={responseFormate(orders)} columns={columns} />

      <ModalComponent
        title={"Order Information"}
        openable={showGenerateModal}
        closable={() => setShowGenerateModal(false)}
        children={
          <POSViewModal
            productContent={productContent}
            orderContent={orderContent}
            orderDetailsContent={orderDetailsContent}
          />
        }
      />
    </>
  );
};

export default POSTable;
