import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import React, { createContext, useContext, useState, ReactNode } from "react";
import {
  AptosIcon,
  CkBtcIcon,
  CkETHIcon,
  ICPIcon,
  JupIcon,
  SolanaIcon,
  SolanaUsdcIcon,
  SuiIcon,
  VenomIcon,
} from "svgIcon";
import { Image } from "antd";
import FlowxIcon from "assets/images/FlowX.png";
import FudIcon from "assets/images/FUD.png";
import NavxIcon from "assets/images/navX.png";
import SacabamIcon from "assets/images/Sacabam.png";

export const copyToClipboard = (getKey: any) => {
  navigator.clipboard.writeText(getKey);
  toastMessage("Copied!");
};

export const toastMessage = (data: any) =>
  toast(data, {
    position: "top-left",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const getStorageData = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return item;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const setStorageData = (key: any, data: any) => {
  // const dataTemp = JSON.stringify(data);
  localStorage.setItem(key, data);
};

export const removeStorageItem = (key: any) => {
  localStorage.removeItem(key);
};

interface GlobalMerchantContextType {
  globalMerchantId: string | null;
  setGlobalMerchantId: (merchantId: string | null) => void;
  globalMerchantRole: string | null;
  setGlobalMerchantRole: (merchantRole: string | null) => void;
}

const GlobalMerchantContext = createContext<
  GlobalMerchantContextType | undefined
>(undefined);

export function GlobalMerchantProvider({ children }: { children: ReactNode }) {
  const [globalMerchantId, setGlobalMerchantId] = useState<string | null>(null);
  const [globalMerchantRole, setGlobalMerchantRole] = useState<string | null>(
    null
  );

  return (
    <GlobalMerchantContext.Provider
      value={{
        globalMerchantId,
        globalMerchantRole,
        setGlobalMerchantId,
        setGlobalMerchantRole,
      }}
    >
      {children}
    </GlobalMerchantContext.Provider>
  );
}

export function toUppercaseLetter(str: any) {
  if (typeof str !== "string") {
    throw new TypeError("Input must be a string");
  }
  if (!str) {
    return ""; // Return empty string for empty input
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isValidUsername(name: any) {
  var regex = /^[A-Za-z]\w{5,29}$/;
  if (!name) {
    return false;
  }
  return regex.test(name);
}

const isEmpty = (value: any) =>
  value === undefined || value === null || value === "";

export const emailValidationExp = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);

export function Email(value: any) {
  // Let's not start a debate on email regex. This is just for an example app!

  // eslint-disable-next-line no-use-before-define
  if (!isEmpty(value) && !emailValidationExp.test(value)) {
    // eslint-disable-line
    return !isEmpty(value) && !emailValidationExp.test(value); // eslint-disable-line
  }
  return false;
}

export const IsMerchantRole = "Merchant";
export const IsUserRole = "User";
export const ChainSUI = "SUI";
export const ChainAptos = "Aptos";
export const ChainVenom = "Venom";
export const ChainSolana = "Solana";
export const ChainSOL = "SOL";
export const ChainSolanaUSDC = "SOL-USDC";
export const ChainSolanaJUP = "JUP";
export const ChainICP = "ICP";
export const ChainCKBTC = "ckBTC";
export const ChainCKETH = "ckETH";
export const ChainCKTestBTC = "ckTESTBTC";

export const validateAddress = (address: string) => {
  const aptosRegex = /^0x[a-fA-F0-9]{64}$/;
  const venomRegex = /^0:[a-fA-F0-9]{64}$/;
  const solanaRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
  const icpRegex = /^[a-z2-7]{5}(-[a-z2-7]{5})*(-[a-z2-7]+)?$/;

  if (aptosRegex.test(address)) {
    return ChainAptos;
  } else if (venomRegex.test(address)) {
    return ChainVenom;
  } else if (solanaRegex.test(address)) {
    return ChainSolana;
  } else if (icpRegex.test(address)) {
    return ChainCKTestBTC;
  } else {
    return false;
  }
};

export const checkCoin = (getCoinName: any, getDigest: any) => {
  switch (getCoinName) {
    case "FUD": {
      return `https://suivision.xyz/txblock/${getDigest}`;
    }
    case "FlowX": {
      return `https://suivision.xyz/txblock/${getDigest}`;
    }
    case "SUI COIN": {
      return `https://testnet.suivision.xyz/txblock/${getDigest}`;
    }
    case "USDC": {
      return `https://suivision.xyz/txblock/${getDigest}`;
    }
    case "Sacabam": {
      return `https://suivision.xyz/txblock/${getDigest}`;
    }
    case "NAVX": {
      return `https://suivision.xyz/txblock/${getDigest}`;
    }
    case "SUI": {
      return `https://testnet.suivision.xyz/txblock/${getDigest}`;
    }
    case "Aptos": {
      return `https://explorer.aptoslabs.com/txn/${getDigest}?network=testnet`;
    }
    case "Venom": {
      return ` https://testnet.venomscan.com/transactions/${getDigest}`;
    }
    case "SOL": {
      return `https://solscan.io/tx/${getDigest}?cluster=devnet`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/tx/${getDigest}?cluster=devnet`;
    }
    case "JUP": {
      return `https://solscan.io/tx/${getDigest}`;
    }
    case "ckBTC": {
      return `https://dashboard.internetcomputer.org/bitcoin/transaction/${getDigest}`;
    }
    case "ckETH": {
      return `https://dashboard.internetcomputer.org/ethereum/transaction/${getDigest}`;
    }
  }
};

export const copyContent = (textToCopy: string) => {
  copy(textToCopy, {
    debug: true,
    message: "Press #{key} to copy",
  });
  toastMessage("Copied!");
};

export const selectedChainIcon = (getCoinName: any) => {
  switch (getCoinName) {
    case "FUD": {
      return <Image src={FudIcon} preview={false} />;
    }
    case "FlowX": {
      return <Image src={FlowxIcon} preview={false} />;
    }
    case "Sacabam": {
      return <Image src={SacabamIcon} preview={false} />;
    }
    case "NAVX": {
      return <Image src={NavxIcon} preview={false} />;
    }
    case "USDC": {
      return <SolanaUsdcIcon />;
    }
    case "SUI COIN": {
      return <SuiIcon />;
    }
    case "SUI": {
      return <SuiIcon />;
    }
    case "Aptos": {
      return <AptosIcon />;
    }
    case "Venom": {
      return <VenomIcon />;
    }
    case "Solana": {
      return <SolanaIcon />;
    }
    case "SOL": {
      return <SolanaIcon />;
    }
    case "SOL-USDC": {
      return <SolanaUsdcIcon />;
    }
    case "JUP": {
      return <JupIcon />;
    }
    case "ICP": {
      return <ICPIcon />;
    }
    case "ckBTC": {
      return <CkBtcIcon />;
    }
    case "ckETH": {
      return <CkETHIcon />;
    }
    case "ckTESTBTC": {
      return <CkBtcIcon />;
    }
  }
};

export const checkAddress = (getCoinName: any, getAddress: any) => {
  switch (getCoinName) {
    case "FUD": {
      return `https://suiexplorer.com/address/${getAddress}`;
    }
    case "FlowX": {
      return `https://suiexplorer.com/address/${getAddress}`;
    }
    case "SUI COIN": {
      return `https://suiexplorer.com/address/${getAddress}?network=testnet`;
    }
    case "USDC": {
      return `https://suiexplorer.com/address/${getAddress}`;
    }
    case "Sacabam": {
      return `https://suiexplorer.com/address/${getAddress}`;
    }
    case "NAVX": {
      return `https://suiexplorer.com/address/${getAddress}`;
    }
    case "SUI": {
      return `https://suiexplorer.com/address/${getAddress}?network=testnet`;
    }
    case "Aptos": {
      return `https://explorer.aptoslabs.com/account/${getAddress}?network=testnet`;
    }
    case "Venom": {
      return `https://testnet.venomscan.com/accounts/${getAddress}`;
    }
    case "SOL": {
      return `https://solscan.io/account/${getAddress}?cluster=devnet`;
    }
    case "SOL-USDC": {
      return `https://solscan.io/account/${getAddress}?cluster=devnet`;
    }
    case "JUP": {
      return `https://solscan.io/account/${getAddress}`;
    }
    case "ckBTC": {
      return `https://dashboard.internetcomputer.org/bitcoin/transaction/${getAddress}`;
    }
    case "ckETH": {
      return `https://dashboard.internetcomputer.org/ethereum/transaction/${getAddress}`;
    }
  }
};

export function useGlobalMerchant() {
  const context = useContext(GlobalMerchantContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalMerchant must be used within a GlobalMerchantProvider"
    );
  }
  return context;
}

export function shortenBigAddress(
  text: string,
  start = 8,
  end = 6,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

export function shortenAddress(
  text: string,
  start = 5,
  end = 3,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

export const isHidePos = true;
