import {
  MerchantAddress,
  MerchantSetting,
  GenerateKey,
  BlockChain,
  MerchantAddressDelete,
  MerchantAddressEnDis,
  Ecommerce,
  UpdateUserDetails,
  MerchantEcommerce,
} from "utils/URLs";
import { get, getWithoutHeader, post, put } from "utils/v2/api";

export const getBlockchain = () => getWithoutHeader(`${BlockChain}`);

export const updateNeededDetails = (data: any) => {
  return post(`${UpdateUserDetails}`, data);
};

export const deleteMerchantAddress = (data: any) =>
  post(`${MerchantAddressDelete}`, data);

export const createMerchantAddress = (data: any) =>
  post(`${MerchantAddress}`, data);

export const getMerchantSetting = () => get(`${MerchantSetting}`);

// export const getSpecificMerchantSetting = (data: any) =>
//   get(`${MerchantSetting}/${data}`);

export const getSpecificMerchantAddress = (data: any) =>
  get(`${MerchantAddress}/${data}`);

export const createMerchantSetting = (data: any) =>
  post(`${MerchantSetting}`, data);

export const putMerchantAddress = (data: any) =>
  put(`${MerchantSetting}`, data);

export const getGenerateKey = () => get(`${GenerateKey}`);

export const postGenerateKey = () => post(`${GenerateKey}`);

export const addressActiveDeactive = (data: any) =>
  post(`${MerchantAddressEnDis}`, data);

export const updateMerchantAddress = (data: any) =>
  put(`${MerchantAddress}`, data);

export const getMerchantEcommerce = (data: any) => get(`${MerchantEcommerce}/${data}`);

export const putMerchantEcommerce = (data: any) => put(`${Ecommerce}`, data);
