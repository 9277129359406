import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import { Button, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getPaylinkAPI } from "apiServices/paylinks";
import { selectedChainIcon, shortenAddress } from "utils/helper";

interface DataType {
  key: React.Key;
  client_address: string;
  payment_id: string;
  amount: string;
  status: string;
  order_type: string;
  coin: string;
  date_time: string;
}

const CustomerListComponent = ({ setCustomerOrderList }: any) => {
  const [orderList, setOrderList] = useState<any[]>([]);
  const [ordersText, setOrdersText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth < 992);

  const columns: ColumnsType<DataType> = [
    {
      title: "Customer ID",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Customer Address",
      dataIndex: "client_address",
      key: "client_address",
    },
    {
      title: "Paid In",
      dataIndex: "coin",
      key: "coin",
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      dataIndex: "operation",
      width: 100,
    },
  ];
  const history = useHistory();

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          sr_no: index + 1,
          client_address: (
            <div className="tAddress">
              {screenSize
                ? shortenAddress(item.client_address)
                : item.client_address}
            </div>
          ),
          coin: (
            <div className="tpaidcoin">
              {item.client_address === "COD"
                ? "Cash"
                : selectedChainIcon(item.coin)}
            </div>
          ),
          operation: (
            <Button
              onClick={() => {
                setCustomerOrderList(item);
              }}
            >
              View History
            </Button>
          ),
        };
      })
    );
  };

  const fetchOrders = (walletEmail: any) => {
    setShowLoader(true);
    try {
      getPaylinkAPI().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            setShowLoader(false);
            setOrdersText("OOPS you dont have any record");
          } else {
            const isOrdersCompleted = responseData.filter(
              (order: any) => order.status !== "pending"
            );
            const ordersData = isOrdersCompleted.filter(
              (item: any) =>
                item.order_type === "POS" || item.order_type === "Ecommerce"
            );
            const groupedByClientAddress: {
              [key: string]: {
                client_address: string;
                coin: string;
                orders: any[];
              };
            } = {};
            // ordersData.forEach((order: any) => {
            //   const { client_address, coin } = order;
            //   if (groupedByClientAddress[client_address]) {
            //     groupedByClientAddress[client_address].orders.push(order);
            //   } else {
            //     groupedByClientAddress[client_address] = {
            //       client_address,
            //       coin,
            //       orders: [order],
            //     };
            //   }
            // });
            ordersData.forEach((order: any) => {
              const { client_address, coin } = order;
              // Check if client_address is not null and not an empty string
              if (
                client_address &&
                client_address.trim() !== "" &&
                client_address !== "COD" &&
                client_address &&
                client_address.length > 25
              ) {
                if (groupedByClientAddress[client_address]) {
                  groupedByClientAddress[client_address].orders.push(order);
                } else {
                  groupedByClientAddress[client_address] = {
                    client_address,
                    coin,
                    orders: [order],
                  };
                }
              }
            });
            // Format the result
            const formattedResult = Object.values(groupedByClientAddress).map(
              ({ client_address, coin, orders }, index) => ({
                sequence: index + 1,
                client_address,
                coin,
                orders,
              })
            );
            if (formattedResult.length > 0) {
              setOrdersText("");
              setShowLoader(false);
              setOrderList(formattedResult);
            } else {
              setShowLoader(false);
              setOrdersText("OOPS you dont have any record");
            }
          }
        }
      });
    } catch (error: any) {}
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const walletEmail = localStorage.getItem("ppay:email");
    if (walletEmail == null || walletEmail == undefined) {
      return history.push("/login");
    } else {
      fetchOrders(walletEmail);
    }
  }, []);

  return (
    <div>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <Table dataSource={responseFormate(orderList)} columns={columns} />
      )}
    </div>
  );
};

export default CustomerListComponent;
