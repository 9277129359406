import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Image, Modal } from "antd";
import {
  PocketpayLogo,
  DashboardIcon,
  PosIcon,
  PaylinksIcon,
  SettingIcon,
  HistoryIcon,
} from "svgIcon";
import {
  IsMerchantRole,
  IsUserRole,
  getStorageData,
  useGlobalMerchant,
  isHidePos,
} from "utils/helper";
import { baseImageURL } from "utils/env";
import { getMerchantSetting } from "apiServices/setting";
import MonogramIcon from "assets/images/mongram.svg";
import ProfileModal from "components/Settings/ProfileModal";
import UserFillupComponent from "components/Dashboard/UserFillupComponent";
import "./styles.css";

const HeaderComponent = () => {
  const getWalletEmail = getStorageData("ppay:email");
  const [merchantContent, setMerchantContent] = useState<any>({});
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { setGlobalMerchantId, setGlobalMerchantRole } = useGlobalMerchant();
  const [menuSetSize, setMenuSetSize] = useState(false);
  let getPathName = window.location.pathname;
  const history = useHistory();

  function startsWithPocketpay(str: any) {
    return str && str.startsWith("pocketpay_");
  }

  const onGetUsersData = (getWalletEmail: any) => {
    try {
      getMerchantSetting().then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.success !== false) {
          if (responseData.length === 0) {
            console.log("You dont have any profile");
          } else {
            const firstItemJSON = JSON.stringify(responseData[0]);
            const parsedData = JSON.parse(firstItemJSON);
            if (parsedData.merchant_id) {
              setMerchantContent(parsedData);
              setGlobalMerchantId(parsedData.merchant_id);
              setGlobalMerchantRole(
                parsedData.merchant_id == "1" ? IsMerchantRole : IsUserRole
              );
            }
            const getMerchantName = startsWithPocketpay(
              parsedData.merchant_name
            );
            const getUserName = startsWithPocketpay(parsedData.username);
            if (
              getMerchantName &&
              getUserName &&
              parsedData.merchant_name === parsedData.username
            ) {
              setShowProfileModal(true);
            }
          }
        }
      });
    } catch (error: any) {}
  };

  const navigateTo = (path: any) => {
    history.push(path);
  };

  const renderTab = (path: any, icon: any, text: any) => (
    <div
      className={getPathName === path ? "active headerTab" : "headerTab"}
      onClick={() => navigateTo(path)}
    >
      {icon}
      <p className="subInnerHeadingPara">{text}</p>
    </div>
  );

  const renderUserProfile = () => (
    <div
      onClick={() => setShowUserProfile(!showUserProfile)}
      className="userProfile cursorPointer"
    >
      <Image
        src={
          merchantContent.logo_path
            ? `${baseImageURL}${merchantContent.logo_path}`
            : MonogramIcon
        }
        alt="business logo"
        preview={false}
      />
    </div>
  );

  useEffect(() => {
    if (getWalletEmail !== null) {
      onGetUsersData(getWalletEmail);
    }
  }, [getWalletEmail]);

  return (
    <div className="headerWrapper">
      <div className="bLogo hLeft">
        <PocketpayLogo />
      </div>
      {/* <div
        className={
          menuSetSize ? "menuOpen headerTabs hCenter" : "headerTabs hCenter"
        }
      >
        {renderTab("/dashboard", <DashboardIcon />, "Dashboard")}
      </div> */}
      <div className="hRight">
        {merchantContent && renderUserProfile()}
        {showUserProfile && (
          <ProfileModal
            setShowUserProfile={setShowUserProfile}
            merchanrContent={merchantContent}
          />
        )}
        <Modal
          title={"Setup Your Profile"}
          open={showProfileModal}
          footer=""
          className="pupdateModalWrapper"
        >
          <UserFillupComponent
            profileContent={merchantContent}
            setShowProfileModal={setShowProfileModal}
          />
        </Modal>
        <div
          className={menuSetSize ? "mobileMenuActive mobileMenu" : "mobileMenu"}
          onClick={() => setMenuSetSize(!menuSetSize)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
