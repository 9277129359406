import { useEffect } from "react";
import { IsMerchantRole, IsUserRole, useGlobalMerchant } from "utils/helper";
import POSMerchantComponent from "../POSMerchantComponent";
import POSFormComponent from "../POSFormComponent";

const POSComponent = () => {
  const { globalMerchantRole, globalMerchantId } = useGlobalMerchant();

  useEffect(() => {}, [globalMerchantRole]);

  return <POSMerchantComponent />;
};

export default POSComponent;
