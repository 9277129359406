/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Button, Image, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { getStorageData, toastMessage, useGlobalMerchant } from "utils/helper";
import { getProduct, removeProduct } from "apiServices/product";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DeleteIcon, EditIcon, InStockIcon, OutStockIcon } from "svgIcon";
import ModalComponent from "components/FormComponent/ModalComponent";
import DeleteComponent from "components/FormComponent/DeleteComponent";

interface DataType {
  key: React.Key;
  product_name: string;
  img: string;
  product_id: string;
  price: string;
  sku: string;
  qty: number;
}

const ProductListComponent = ({
  setSelectedProduct,
  haveToReloadProductList,
  setShowViewProductList,
  setHaveToReloadProductList,
}: any) => {
  const { globalMerchantId } = useGlobalMerchant();
  const [productList, setProductList] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "SR No",
      dataIndex: "sr_no",
      key: "sr_no",
    },
    {
      title: "Product Image",
      dataIndex: "img",
      key: "img",
    },
    {
      title: "Name",
      dataIndex: "product_name",
      key: "product_name",
    },
    {
      title: "Price (USD)",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "operation",
      dataIndex: "operation",
      fixed: "right",
      width: 100,
    },
  ];

  const fetchProducts = (globalMerchantId: any) => {
    setShowLoader(true);
    try {
      getProduct(globalMerchantId).then((response) => {
        const responseData = response.data || response;
        if (responseData !== null && responseData.status !== false) {
          if (responseData.length !== 0) {
            var result = responseData.reverse();
            setShowLoader(false);
            setProductList(result);
          }
        } else {
          setShowLoader(false);
          setProductList([]);
        }
      });
    } catch (error: any) { }
  };

  const onConfirmation = () => {
    if (deleteOrderId !== "") {
      onRemoveClick(deleteOrderId);
    } else {
      toastMessage("Something went wrong");
    }
  };

  const onRemoveClick = (getProductId: any) => {
    removeProduct(getProductId)
      .then((response) => {
        const responseData = response.data || response;
        if (responseData) {
          toastMessage("Deleted Successfully!");
          setShowDeleteModal(false);
          fetchProducts(globalMerchantId);
        }
      })
      .catch((error) => { });
  };

  const responseFormate = (data: any) => {
    return (
      data &&
      data.map((item: any, index: number) => {
        return {
          key: index,
          sr_no: index + 1,
          img: (
            <div className="tImage">
              {item.img !== null ? (
                <Image
                  src={`${baseImageURL}${item.img}`}
                  alt="image"
                  preview={false}
                />
              ) : (
                <Image src={MonogramIcon} alt="image" preview={false} />
              )}
            </div>
          ),
          product_name: <div className="tName">{item.product_name}</div>,
          price: <div className="tAmount">{item.price}</div>,
          qty: <div className="tQty">{item.qty}</div>,
          sku: <div className="tSku">{item.sku}</div>,
          status:
            item.qty <= 0 ? (
              <div className="tStatus tStatusOS"><span><OutStockIcon /></span>Out Of Stock</div>
            ) : (
              <div className="tStatus tStatusIS"><span><InStockIcon /></span>In Stock</div>
            ),
          operation: (
            <>
              <div className="actionIcons">
                <div
                  onClick={() => {
                    setSelectedProduct(item);
                    setShowViewProductList(false);
                  }}
                  className="editIcon cursorPointer"
                >
                  <EditIcon />
                </div>
                <div
                  onClick={() => {
                    setDeleteOrderId(item.product_id);
                    setShowDeleteModal(true);
                  }}
                  className="deleteIcon cursorPointer"
                >
                  <DeleteIcon />
                </div>
              </div>
            </>
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (globalMerchantId !== null && haveToReloadProductList) {
      fetchProducts(globalMerchantId);
      setHaveToReloadProductList(false);
    }
  }, [haveToReloadProductList]);

  useEffect(() => {
    globalMerchantId !== null && fetchProducts(globalMerchantId);
  }, []);

  return (
    <>
      {showLoader ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
      ) : (
        <>
          <Table dataSource={responseFormate(productList)} columns={columns} />

          <ModalComponent
            openable={showDeleteModal}
            closable={() => setShowDeleteModal(false)}
            children={
              <DeleteComponent
                setShowDeleteModal={setShowDeleteModal}
                onConfirmation={onConfirmation}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default ProductListComponent;
