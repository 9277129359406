import React, { useState } from "react";
import { Image, Tooltip } from "antd";
import { CopyIcon } from "svgIcon";
import {
  ChainCKTestBTC,
  checkAddress,
  checkCoin,
  copyContent,
  selectedChainIcon,
  shortenAddress,
  toastMessage,
} from "utils/helper";
import { baseImageURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";

const POSViewModal = ({
  productContent,
  orderContent,
  orderDetailsContent,
}: any) => {
  const [url, setUrl] = useState("");
  const [addressUrl, setAddressUrl] = useState("");

  const onRenderAddress = (getOrders: any) => {
    const renderAddress = checkCoin(getOrders.coin, getOrders.digest);
    if (renderAddress) {
      setUrl(renderAddress);
    }
  };

  const onRenderSenderAddress = (getOrders: any) => {
    const renderAddress = checkAddress(
      getOrders.coin,
      getOrders.client_address
    );
    if (renderAddress) {
      setAddressUrl(renderAddress);
    }
  };

  return (
    <>
      <div className="posOrderInfo">
        <div className="productItemsWrapper">
          <div className="productItemsHeader">
            <p className="subInnerHeading">Product Details</p>
            <p className="subHeadingpara">
              Status: <span className="sComplete">{orderContent.status}</span>
            </p>
          </div>
          <div className="productItems">
            {productContent.map((product: any) => (
              <div className="productItem">
                <div className="commonProfilePic">
                  {product.image_path !== null ? (
                    <Image
                      src={`${baseImageURL}${product.image_path}`}
                      alt="product image"
                      preview={false}
                    />
                  ) : (
                    <Image
                      src={MonogramIcon}
                      alt="product image"
                      preview={false}
                    />
                  )}
                </div>
                <p className="subInnerHeadingPara">{product.name}</p>
                <p className="subInnerHeadingPara">{product.qty}</p>
                <span className="subInnerHeading">{product.price} USD</span>
              </div>
            ))}
          </div>
        </div>
        {/* {orderContent.digest !== "COD" &&
          Object.keys(orderContent).length > 0 &&
          orderContent.status === "completed" && (
            <>
              <div className="paymentDetails">
                <div className="paymentDetailsHeader">
                  <p className="subInnerHeading">Payment Details</p>
                  <Tooltip title={`${orderContent.coin}`}>
                    <span>{selectedChainIcon(orderContent.coin)}</span>
                  </Tooltip>
                </div>
                <div className="paymentDetailsBody">
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">TimeStamp : </p>
                    <p className="subInnerHeading">{orderContent.date_time}</p>
                  </div>
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Merchant Name : </p>
                    <p className="subInnerHeading">
                      {orderContent.merchant_email}
                    </p>
                  </div>
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Order Type : </p>
                    <p className="subInnerHeading">
                      {" "}
                      {orderContent.order_type}
                    </p>
                  </div>
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Order ID : </p>
                    <p className="subInnerHeading">
                      {" "}
                      {orderContent.payment_id}
                    </p>
                  </div>
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Amount : </p>
                    <p className="subInnerHeading">
                      {" "}
                      {orderContent.amount} USD
                    </p>
                  </div>
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Paid In : </p>
                    <p className="subInnerHeading">
                      {" "}
                      {orderContent.coin_amount} {orderContent.coin}
                    </p>
                  </div>
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Conversion Rate : </p>
                    <p className="subInnerHeading">
                      {" "}
                      1 {orderContent.coin} ={" "}
                      {(orderContent.amount / orderContent.coin_amount).toFixed(
                        4
                      )}
                      * USD
                    </p>
                  </div>

                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Txn ID :</p>
                    {orderContent.coin === ChainCKTestBTC ? (
                      <p
                        className="subInnerHeading"
                        onClick={() =>
                          toastMessage(
                            "CKTESTBTC Testnet explorer is not available."
                          )
                        }
                      >
                        <a href={url} target="_blank" rel="noreferrer">
                          {shortenAddress(orderContent.digest)}
                        </a>
                        <span>
                          <CopyIcon />
                        </span>
                      </p>
                    ) : (
                      <p
                        className="subInnerHeading"
                        onClick={() => onRenderAddress(orderContent)}
                      >
                        <a href={url} target="_blank" rel="noreferrer">
                          {shortenAddress(orderContent.digest)}
                        </a>
                        <span onClick={() => copyContent(url)}>
                          <CopyIcon />
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="paymentDetailsBodyContent">
                    <p className="subInnerHeadingPara">Sender Address :</p>
                    <p
                      className="subInnerHeading"
                      onClick={() => onRenderSenderAddress(orderContent)}
                    >
                      {orderContent.client_address == null ||
                      orderContent.client_address == " " ? (
                        "-"
                      ) : (
                        <>
                          <p>
                            <a
                              href={addressUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {shortenAddress(orderContent.client_address)}
                            </a>
                            <span onClick={() => copyContent(addressUrl)}>
                              <CopyIcon />
                            </span>
                          </p>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

        {Object.keys(orderContent).length > 0 &&
          orderContent.status === "completed" && (
            <>
              {Object.keys(orderDetailsContent).length > 0 &&
                orderDetailsContent.address_one !== null && (
                  <>
                    <div className="paymentDetails">
                      <div className="paymentDetailsHeader">
                        <p className="subInnerHeading">Shipping Address</p>
                      </div>
                      <p className="subInnerHeadingPara">
                        {orderDetailsContent.address_one}{" "}
                        {orderDetailsContent.address_two}
                      </p>
                      <p className="subInnerHeadingPara">
                        {orderDetailsContent.city}
                      </p>
                      <p className="subInnerHeadingPara">
                        {orderDetailsContent.state}{" "}
                        {orderDetailsContent.zip_code}{" "}
                        {orderDetailsContent.country}
                      </p>
                    </div>
                    <div className="paymentDetails">
                      {orderDetailsContent.same_as_billing === "yes" ? (
                        <>
                          <div className="paymentDetailsHeader">
                            <p className="subInnerHeading">Billing Address</p>
                          </div>
                          <p className="subInnerHeadingPara">
                            Same as Shipping address
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="paymentDetailsHeader">
                            <p className="subInnerHeading">Shipping Address</p>
                          </div>
                          <p className="subInnerHeadingPara">
                            {orderDetailsContent.saddress_one}{" "}
                            {orderDetailsContent.saddress_two}
                          </p>
                          <p className="subInnerHeadingPara">
                            {orderDetailsContent.scity}
                          </p>
                          <p className="subInnerHeadingPara">
                            {orderDetailsContent.sstate}{" "}
                            {orderDetailsContent.szip_code}{" "}
                            {orderDetailsContent.scountry}
                          </p>
                        </>
                      )}
                    </div>
                  </>
                )}
            </>
          )} */}
      </div>
    </>
  );
};

export default POSViewModal;
