import React, { useEffect, useState } from "react";
import { Button, Image } from "antd";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRImage from "assets/images/qr.png";
import Signupcomponent from "../Signupcomponent";
import { PocketpayLogo } from "svgIcon";
// import WithQRComponent from "../WithQRComponent";
import LoginFormComponent from "../LoginFormComponent";

const SelectionComponent = () => {
  const [isSignWithQr, setIsSignWithQr] = useState(false);
  const [isLoginWithQr, setIsLoginWithQr] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [signFormData, setSignFormData] = useState({});
  const history = useHistory();

  useEffect(() => {
    const getWalletKey = localStorage.getItem("ppay:email");
    if (getWalletKey !== null && getWalletKey !== undefined) {
      history.push("/pos");
    }
  }, []);

  return (
    <div className="loginFormWrapper">
      <div className="loginFormWrapperLogo">
        <PocketpayLogo />
      </div>
      <ToastContainer />
      <div className="loginForm boxShadow radius10">
        {showSignup ? (
          <div className="loginArea">
            <div className="lLeft lSide">
              <p className="mainHeading">Welcome</p>
              <p className="subInnerHeadingPara">
                We are excited to have you join us
              </p>
              <Signupcomponent
                setIsSignWithQr={setIsSignWithQr}
                setSignFormData={setSignFormData}
              />
              <h4 className="loginOption cursorPointer">
                already a user?{" "}
                <span
                  className="cursorPointer"
                  onClick={() => setShowSignup(false)}
                >
                  Login
                </span>
              </h4>
            </div>
            {/* <div className="lRight lSide">
              {isSignWithQr ? (
                <WithQRComponent
                  title={"Sign Up"}
                  setIsWithQr={setIsSignWithQr}
                  setShowSignup={setShowSignup}
                  signFormData={signFormData}
                />
              ) : (
                <Image src={QRImage} preview={false} />
              )}
            </div> */}
          </div>
        ) : (
          <div className="loginArea">
            <div className="lLeft lSide">
              <p className="mainHeading">Welcome</p>
              <p className="subInnerHeadingPara">
                We are excited to see you again
              </p>
              <LoginFormComponent />
              {/* <p className="loginOption">
                need an account?{" "}
                <span
                  className="cursorPointer"
                  onClick={() => setShowSignup(true)}
                >
                  Register
                </span>
              </p> */}
            </div>
            {/* <div className="lRight lSide">
              {isLoginWithQr ? (
                <WithQRComponent
                  title={"Login"}
                  setIsWithQr={setIsLoginWithQr}
                />
              ) : (
                <>
                  <div className="logWqr">
                    <Image src={QRImage} preview={false} />
                    <button
                      className="cursorPointer commonButtonSmall"
                      onClick={() => setIsLoginWithQr(!isLoginWithQr)}
                    >
                      Login With QR
                    </button>
                  </div>
                </>
              )}
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectionComponent;
