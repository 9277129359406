import React from "react";
import propTypes from "prop-types";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import Layout from "./DefaultLayout";
import DashboardPage from "../pages/Dashboard";
import PaymentsPage from "../pages/Paylinks";
import NotFound from "../components/NotFound";
import POSPage from "../pages/POS";
import SettingPage from "pages/Setting";
import { getStorageData } from "utils/helper";
import CustomerPage from "pages/Customers";

const CRoutes = (props: any) => {
  const RouteRedirect = () => {
    const walletEmail = localStorage.getItem("ppay:email");
    if (walletEmail !== null && walletEmail !== undefined) {
      let pathname = "/pos";
      return pathname;
    } else {
      let pathname = "/login";
      return pathname;
    }
  };

  return (
    <Switch>
      <Redirect exact from="/" to={RouteRedirect()} />
      {/* <Route
        path="/setting"
        component={(props: any) => (
          <Layout {...props} Component={SettingPage} />
        )}
      /> */}
      <Route
        path="/pos"
        component={(props: any) => <Layout {...props} Component={POSPage} />}
      />
      <Route
        path="/*"
        component={(props: any) => <Layout {...props} Component={NotFound} />}
      />
    </Switch>
  );
};

CRoutes.propTypes = {
  location: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
};

export default CRoutes;
