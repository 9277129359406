import React, { useState } from "react";
import { Button, Form, Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { updateNeededDetails } from "apiServices/setting";
import { Email, isValidUsername, toastMessage } from "utils/helper";

interface SignupcomponentState {
  id: string;
  name: string;
  username: string;
  useremail: string;
  password: string;
}

const UserFillupComponent = ({ profileContent, setShowProfileModal }: any) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [formData, setFormData] = useState<SignupcomponentState>({
    id: "",
    name: "",
    username: "",
    useremail: "",
    password: "",
  });

  const onUpdateDetails = () => {
    setOnBtnLoader(true);
    const newData = {
      id: profileContent.user_id,
      name: formData.name,
      username: formData.username,
      email: formData.useremail,
      password: formData.password,
    };
    try {
      updateNeededDetails(newData)
        .then((response) => {
          const responseData = response as any;
          if (responseData && responseData?.success === true) {
            toastMessage("Profile created successfully");
            setOnBtnLoader(false);
            setShowProfileModal(false);
            history.push("/settings");
          }
        })
        .catch((error) => {
          setOnBtnLoader(false);
          toastMessage(error);
        });
    } catch (error) {
      setOnBtnLoader(false);
      toastMessage(error);
    }
  };

  const formValidation = () => {
    if (
      formData.name !== "" &&
      formData.username !== "" &&
      formData.useremail !== "" &&
      formData.password !== ""
    ) {
      if (formData.name !== formData.username) {
        const validUName = isValidUsername(formData.username);
        const validEmail = Email(formData.useremail);
        if (!validEmail && validUName) {
          onUpdateDetails();
        } else {
          toastMessage("Please fillup proper email");
        }
      } else {
        toastMessage("Username and Name should be different");
      }
    } else {
      toastMessage("All fields are required");
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          name="Name"
          label="Name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input
            name="name"
            placeholder="Enter name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          name="Username"
          label="Username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input
            name="username"
            placeholder="Enter username"
            value={formData.username}
            onChange={(e) =>
              setFormData({ ...formData, username: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="Email"
          label="Email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input
            name="useremail"
            placeholder="Enter email"
            autoComplete="new-email"
            value={formData.useremail}
            onChange={(e) =>
              setFormData({ ...formData, useremail: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          name="Password"
          label="Password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            name="password"
            placeholder="Enter password"
            autoComplete="new-password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
          />
        </Form.Item>
      </Form>
      <div>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => !onBtnLoader && formValidation()}
          className="commonButton mt-20"
        >
          {onBtnLoader ? (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          ) : (
            "Submit"
          )}
        </Button>
        <p
          className="wantlogout"
          onClick={() => {
            toastMessage("Logout successfully");
            localStorage.clear();
            history.push("/login");
          }}
        >
          Want to <span>logout?</span>
        </p>
      </div>
    </>
  );
};

export default UserFillupComponent;
