import {
  MerchantOrderProduct,
  MerchantOrders,
  MerchantOrdersDetails,
  MerchantWalletOrders,
} from "utils/URLs";
import { get } from "utils/v2/api";

export const getMerchantOrders = () => get(`${MerchantOrders}`);
export const getMerchantWalletOrders = (data: any) =>
  get(`${MerchantWalletOrders}/${data}`);
export const getMerchantOrderProduct = (data: any) =>
  get(`${MerchantOrderProduct}/${data}`);
export const getMerchantOrderDetails = (data: any) =>
  get(`${MerchantOrdersDetails}/${data}`);
