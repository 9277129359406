import React, { useEffect, useState } from "react";
import { Button, Form, Image, Input } from "antd";
import { getStorageData, toastMessage } from "utils/helper";
import { baseImageURL, baseURL } from "utils/env";
import MonogramIcon from "assets/images/mongram.svg";
import { UploadOutlined } from "@ant-design/icons";
import Upload, { RcFile, UploadChangeParam } from "antd/es/upload";
import { DeleteIcon, EditIcon } from "svgIcon";
import { deleteCategory } from "apiServices/category";
import ModalComponent from "components/FormComponent/ModalComponent";
import DeleteComponent from "components/FormComponent/DeleteComponent";

interface CategorySubComponentState {
  name: string;
}

const CategoryChildComponent = ({
  setShowChildComponent,
  selectedCategory,
  setHaveToReloadCategoryList,
}: any) => {
  const getUserToken = getStorageData("ppay:user");
  const [file, setFile] = useState<File | null>(null);
  const [onBtnLoader, setOnBtnLoader] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setFormData] = useState<CategorySubComponentState>({
    name: "",
  });
  const [isEditable, setIsEditable] = useState(false);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toastMessage("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toastMessage("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    setFile(file);
    return false;
  };

  const handleChange = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onConfirmation = () => {
    if (deleteOrderId !== "") {
      onRemoveCategory(deleteOrderId);
    } else {
      toastMessage("Something went wrong");
    }
  };

  const onRemoveCategory = (getKey: any) => {
    const newObject = {
      category_id: getKey,
    };
    try {
      deleteCategory(newObject)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.success !== false) {
            toastMessage("Deleted Successfully");
            setHaveToReloadCategoryList(true);
            setShowDeleteModal(false);
            setShowChildComponent(false);
          } else {
            toastMessage("OOPS! First delete assign product");
            setShowDeleteModal(false);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const onEditEvent = () => {
    setIsEditable(false);
    setFormData({
      name: "",
    });
  };

  const onCreateSubCategory = async () => {
    // if (!file) {
    //   toastMessage("Please select an image first.");
    //   return;
    // }
    if (formData.name !== "") {
      if (getUserToken !== null) {
        setOnBtnLoader(true);
        if (isEditable) {
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("category_name", formData.name);
          try {
            const response = await fetch(
              `${baseURL}/update_categories/${selectedCategory.category_id}`,
              {
                method: "PUT",
                body: formDataImage,
                headers: {
                  Authorization: getUserToken,
                },
              }
            );
            const data = await response.json();
            if (data) {
              setOnBtnLoader(false);
              toastMessage("Updated successfully");
              onEditEvent();
              setHaveToReloadCategoryList(true);
              // setShowChildComponent(false);
            }
          } catch (error: any) {}
        } else {
          const formDataImage = new FormData();
          file && formDataImage.append("image", file);
          formDataImage.append("category_name", formData.name);
          formDataImage.append(
            "parent_category_id",
            selectedCategory.category_id
          );
          try {
            const response = await fetch(
              `${baseURL}/create_single_categories`,
              {
                method: "POST",
                body: formDataImage,
                headers: {
                  Authorization: getUserToken,
                },
              }
            );
            const data = await response.json();
            if (data) {
              setOnBtnLoader(false);
              toastMessage("Inserted successfully");
              setHaveToReloadCategoryList(true);
              setShowChildComponent(false);
            }
          } catch (error: any) {}
        }
      }
    } else {
      toastMessage("Please enter category name");
    }
  };

  useEffect(() => {
    onEditEvent();
  }, [selectedCategory]);

  return (
    <div className="categoryDetails">
      {isEditable ? (
        <h2 className="subHeading">Edit form</h2>
      ) : (
        <div className="categoryDetailsHeader">
          <div className="categoryDetailsHeaderLeft">
            <div className="commonProfileWrapper">
              <div className="commonProfilePic">
                {selectedCategory.image_path !== null ? (
                  <Image
                    src={`${baseImageURL}${selectedCategory.image_path}`}
                    alt="paylink image"
                    preview={false}
                  />
                ) : (
                  <Image
                    src={MonogramIcon}
                    alt="paylink image"
                    preview={false}
                  />
                )}
              </div>
            </div>
            <p className="subHeading">
              {" "}
              {selectedCategory.category_name} :{" "}
              <span className="subInnerHeadingPara">Parent Category</span>
            </p>
          </div>
          <div className="actionIcons">
            <div
              className="editIcon"
              onClick={() => {
                setIsEditable(true);
                setFormData({
                  name: selectedCategory.category_name,
                });
              }}
            >
              <EditIcon />
            </div>
            <div
              className="deleteIcon"
              onClick={() => {
                setDeleteOrderId(selectedCategory.category_id);
                setShowDeleteModal(true);
              }}
            >
              <DeleteIcon />
            </div>
          </div>
        </div>
      )}
      <Form
        initialValues={{ remember: true }}
        layout="vertical"
        className="categoryDetailsBody"
      >
        <Form.Item
          label="Category Name"
          rules={[{ required: true, message: "Please enter price!" }]}
        >
          <Input
            placeholder="Enter subcategory name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </Form.Item>
        <div className="commonProfileWrapper">
          <Form.Item
            name="upload"
            label="Upload Image"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            // rules={[{ required: true, message: "Please upload an image!" }]}
          >
            <Upload
              name="avatar"
              listType="picture"
              beforeUpload={beforeUpload}
              onChange={handleChange}
              onRemove={() => setFile(null)}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          {isEditable && (
            <div className="commonProfilePic">
              {selectedCategory.image_path !== null ? (
                <Image
                  src={`${baseImageURL}${selectedCategory.image_path}`}
                  alt="paylink image"
                  preview={false}
                />
              ) : (
                <Image src={MonogramIcon} alt="paylink image" preview={false} />
              )}
            </div>
          )}
        </div>
        <div className="imageUploadLimit">
          <p>
            Profile image must be JPG, JPEG, or PNG, no larger than 2MB, and 256
            x 256 pixels in size.
          </p>
        </div>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="commonButtonSmall"
            onClick={() => !onBtnLoader && onCreateSubCategory()}
          >
            {onBtnLoader ? "Loading..." : isEditable ? "Update" : "Submit"}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="commonButtonSmallTrans"
            onClick={() =>
              isEditable ? onEditEvent() : setShowChildComponent(false)
            }
          >
            Close
          </Button>
        </Form.Item>
      </Form>

      <ModalComponent
        openable={showDeleteModal}
        closable={() => setShowDeleteModal(false)}
        children={
          <DeleteComponent
            setShowDeleteModal={setShowDeleteModal}
            onConfirmation={onConfirmation}
          />
        }
      />
    </div>
  );
};

export default CategoryChildComponent;
